<script setup>
import { onMounted, ref } from "vue";
import { useUserStore } from "../../../stores/user";
import { useRoute } from "vue-router/auto";
import { setPageTitle } from "../../../utils/pageTitle";
import OrderHeader from "../../../components/order/OrderHeader.vue";
import OrderAppointmentCard from "@/components/order/OrderAppointmentCard.vue";
import useOutboundOrders from "@/hooks/useOutboundOrders";
import OutboundOrderLayout from "@/layouts/OutboundOrderLayout.vue";

const {
  outboundOrder,
  outboundLoadingCount,
  fetchOutboundOrder,
  fetchOutboundOrderAppointments,
} = useOutboundOrders();

const userStore = useUserStore();
const route = useRoute();

const appt = ref({});
const closedAppt = ref(null);
const openApptHistory = ref([]);
const closedApptHistory = ref([]);

onMounted(async () => {
  await fetchOutboundOrder(route.params.id).then(() => {
    setPageTitle(`Outbound Order #OUT-${outboundOrder.value.id}`);
  });
  appt.value = await fetchOutboundOrderAppointments(route.params.id);
});

</script>

<template>
  <OutboundOrderLayout :outboundOrder="outboundOrder" v-if="outboundOrder">
    <div class="w-100">
      <div v-if="userStore.user && outboundOrder" class="px-4">
        <div class="w-100 px-2">
          <div class="w-100">
            <OrderHeader
              orderType="outbound"
              :orderId="outboundOrder.id"
              :status="outboundOrder.order_status.short_label"
            />
          </div>

          <v-card v-if="!appt && outboundLoadingCount == 0" class="mt-4 pa-2 w-auto">
            <v-card-title> Appointments </v-card-title>
            <v-card-text>
              <p>No appointments found.</p>
            </v-card-text>
          </v-card>

          <!-- Open Appointments -->
          <OrderAppointmentCard :key="appt" title="Open Appointments" :appt="appt" orderType="outbound" :history="openApptHistory"/>
          <!-- Closed Appointments -->
          <OrderAppointmentCard :key="closedAppt" v-if="closedAppt" title="Appointments" :appt="closedAppt" orderType="outbound" :history="closedApptHistory"/>
          
        </div>
      </div>
    </div>
  </OutboundOrderLayout>
</template>